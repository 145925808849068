import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { SelectModule } from 'primeng/select';
import { map } from 'rxjs';

import { PaginatedCurrencyList } from '@offconon/core-api';
import { SystemService } from '@offconon/core-api';

@Component({
  selector: 'offconon-all-currency-dropdown-list',
  templateUrl: './all-currency-dropdown-list.component.html',
  imports: [SelectModule, FormsModule, SharedModule, TranslateModule],
})
export class AllCurrencyDropdownListComponent implements OnInit {
  private systemService = inject(SystemService);
  private translate = inject(TranslateService);

  @Output() selectedCurrencyEvent = new EventEmitter<any>();
  @Input() selected_iso_code: any;
  @Input() selected_currencies: any;

  private destroyRef = inject(DestroyRef);

  currencies: any[];
  selectedCurrency: any;

  ngOnInit(): void {
    this.getCurrencies();
  }

  getCurrencies(iso_code = '') {
    this.systemService
      .systemCurrenciesList(1, 200, {
        iso_code: iso_code,
        currencies: this.selected_currencies,
      })
      .pipe(
        map((res: PaginatedCurrencyList) => {
          return res?.results?.map((item: any) => {
            return {
              name: item?.iso_code + ' (' + this.translate.instant('' + item?.description) + ')',
              iso_code: item?.iso_code,
            };
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((res: any) => {
        this.currencies = res;
        if (this.selected_iso_code) {
          this.selectedCurrency = this.currencies.find(
            (el) => el.iso_code === this.selected_iso_code,
          );
        } else {
          this.selectedCurrency = this.currencies[0];
        }
        this.selectedCurrencyEvent.emit(this.selectedCurrency);
      });
  }

  select() {
    this.selectedCurrencyEvent.emit(this.selectedCurrency);
  }
}
