@if (selectedCurrency) {
  <p-select
    optionLabel="name"
    filterBy="name"
    [options]="currencies"
    [filter]="true"
    [showClear]="false"
    [styleClass]="'w-full'"
    [panelStyleClass]="'w-full'"
    placeholder="{{ 'Select one' | translate }}"
    [(ngModel)]="selectedCurrency"
    (onChange)="select()">
    <ng-template #selectedItem>
      @if (selectedCurrency) {
        <div>
          {{ selectedCurrency?.name }}
        </div>
      }
    </ng-template>
    <ng-template #item let-currency>
      {{ currency?.name }}
    </ng-template>
  </p-select>
}
